import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import './CreativeArts.css';
import creativeArtsData from '../data/creativeArtsProjects.json';
import { FaGuitar, FaMicrophone, FaMusic } from 'react-icons/fa';
import { PiPianoKeysFill } from "react-icons/pi";

interface MediaItem {
  type: string;
  src?: string;
  content?: string;
  description?: string;
}

interface Skill {
  name: string;
  years: number;
  icon: string;
}

interface MusicSection {
  title: string;
  media: MediaItem[];
  skills?: Skill[];
  tools?: string[];
  platforms?: string[];
  education?: string[] | string;
}

const iconMap: { [key: string]: React.ElementType } = {
  FaGuitar,
  FaMicrophone,
  FaMusic,
  PiPianoKeysFill,
};

const CreativeArts: React.FC = () => {
  const [sections, setSections] = useState<MusicSection[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    setSections(creativeArtsData.sections as MusicSection[]);
  }, []);

  const openModal = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const renderMusicInfo = (section: MusicSection) => {
    return (
      <div className="music-info">
        <div className="music-skills">
          {section.skills?.map((skill, index) => {
            const Icon = iconMap[skill.icon];
            return (
              <p key={index}>
                {Icon && <Icon />} {skill.name} ({skill.years} years)
              </p>
            );
          })}
        </div>
        <div className="music-tools">
          <h3>Tools & Platforms</h3>
          <p>{section.tools?.join(', ')}</p>
          <p>{section.platforms?.join(', ')}</p>
        </div>
        {section.education && (
          <div className="music-education">
            <h3>Education</h3>
            {Array.isArray(section.education) ? (
              section.education.map((edu, index) => (
                <p key={index}>{edu}</p>
              ))
            ) : (
              <p>{section.education}</p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="creative-arts-container">
      <h1>Creative Arts</h1>
      <Link to="/" className="ca-home-link">Go to Homepage</Link>
      {sections.map((section, index) => (
        <section key={index} className="creative-arts-section">
          <h2>{section.title}</h2>
          {section.media.map((mediaItem, mediaIndex) => (
            mediaItem.type === 'text' && (
              <p key={mediaIndex}>{mediaItem.content}</p>
            )
          ))}
          {section.title === "Music Production" && renderMusicInfo(section)}
          <div className={`media-container ${section.title === 'Digital Painting' ? 'digital-painting-grid' : ''}`}>
            {section.media.map((mediaItem, mediaIndex) => (
              mediaItem.type !== 'text' && (
                <div key={mediaIndex} className="media-item">
                  {mediaItem.type === 'video' && (
                    <iframe
                      width="560"
                      height="315"
                      src={mediaItem.src}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                  {mediaItem.type === 'image' && (
                    <img
                      src={mediaItem.src}
                      alt={mediaItem.description}
                      onClick={() => openModal(mediaItem.src!)}
                    />
                  )}
                </div>
              )
            ))}
          </div>
        </section>
      ))}

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <motion.img
              src={selectedImage}
              alt="Full-screen image"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CreativeArts;