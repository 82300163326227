import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import techData from '../data/techProjects.json';
import { getTechIcon } from '../utils/techUtils';
import './Tech.css';
import Modal from 'react-modal'; // You'll need to install this package
import { createPortal } from 'react-dom';

interface Project {
  title: string;
  date: string;
  description: string;
  roles?: string[];
  technologies: string[];
  details?: string[];
  images?: string[];
}

interface TechnologyGroup {
  [key: string]: string[];
}

const Tech: React.FC = () => {
  const projects: Project[] = techData.projects;
  const allTechnologies: TechnologyGroup = techData.allTechnologies;
  const [expandedProject, setExpandedProject] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string>("All");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px 0px 0% 0px', // Adjust this value to control when the animation starts
      threshold: [0, 0.2, 0.4, 0.6, 0.8, 1] // Multiple thresholds for smoother animation
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement;
        if (entry.isIntersecting) {
          target.classList.add('fade-in');
          target.style.setProperty('--intersection-ratio', entry.intersectionRatio.toString());
        } else {
          target.classList.remove('fade-in');
          target.style.setProperty('--intersection-ratio', '0');
        }
      });
    }, options);

    const timelineItems = timelineRef.current?.querySelectorAll('.timeline-item');
    timelineItems?.forEach(item => {
      observer.observe(item);
    });

    return () => {
      timelineItems?.forEach(item => {
        observer.unobserve(item);
      });
    };
  }, []);

  const toggleProject = (index: number) => {
    setExpandedProject(expandedProject === index ? null : index);
  };

  const openImageModal = (image: string) => {
    setSelectedImage(image);
    setImageModalIsOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setImageModalIsOpen(false);
  };

  const openProjectModal = (project: Project) => {
    setSelectedProject(project);
    setModalIsOpen(true);
  };

  const closeProjectModal = () => {
    setModalIsOpen(false);
    setSelectedProject(null);
  };

  const allTechnologiesFlat = useMemo(() => {
    const allTechs = Object.values(allTechnologies).flat();
    return Array.from(new Set(allTechs)); // Remove duplicates and convert back to array
  }, [allTechnologies]);

  const sortedTechnologies = useMemo(() => {
    if (selectedGroup === "All") {
      return allTechnologiesFlat;
    }
    return [
      ...allTechnologies[selectedGroup],
      ...allTechnologiesFlat.filter(tech => !allTechnologies[selectedGroup].includes(tech))
    ];
  }, [selectedGroup, allTechnologies, allTechnologiesFlat]);

  const filterTechnologies = (tech: string) => {
    if (selectedGroup === "All") return true;
    return allTechnologies[selectedGroup].includes(tech);
  };

  const filteredTechnologies = useMemo(() => {
    return sortedTechnologies.filter(tech =>
      tech.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedTechnologies, searchTerm]);

  const filteredAndSortedTechnologies = useMemo(() => {
    const filtered = sortedTechnologies.filter(tech =>
      tech.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const nonFiltered = sortedTechnologies.filter(tech =>
      !tech.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return [...filtered, ...nonFiltered];
  }, [sortedTechnologies, searchTerm]);

  const isTechActive = (tech: string) => {
    const matchesSearch = tech.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filterTechnologies(tech);
    return (searchTerm === "" && matchesFilter) || (searchTerm !== "" && matchesSearch);
  };

  return (
    <div className="tech-container">
      <div className="timeline-section">
        <div className="title-container">
          <h1>Tech & Fintech Experience</h1>
          <Link to="/" className="home-link">Go to Homepage</Link>
        </div>
        <div className="timeline" ref={timelineRef}>
          {projects.map((project, index) => (
            <div
              key={index}
              className="timeline-item"
              onClick={() => openProjectModal(project)}
              style={{ '--item-index': index } as React.CSSProperties}
            >
              <div className="timeline-content">
                <h2 style={{ wordBreak: 'break-word' }}>{project.title}</h2>
                <p className="project-date">{project.date}</p>
                <div className="tech-icons">
                  {project.technologies.slice(0, 4).map((tech, techIndex) => (
                    <div key={techIndex} className="tech-icon">
                      {getTechIcon(tech)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="technologies-section">
        <div className="all-technologies">
          <h2>Technologies I work with</h2>
          <div className="search-box">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search technologies..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="technology-filters">
            <button
              className={selectedGroup === "All" ? "active" : ""}
              onClick={() => setSelectedGroup("All")}
            >
              All
            </button>
            {Object.keys(allTechnologies).map((group) => (
              <button
                key={group}
                className={selectedGroup === group ? "active" : ""}
                onClick={() => setSelectedGroup(group)}
              >
                {group}
              </button>
            ))}
          </div>
          <div className="tech-icons-full">
            {filteredAndSortedTechnologies.map((tech, index) => (
              <div
                key={tech}
                className={`tech-icon-full ${isTechActive(tech) ? "active" : "grayed-out"}`}
              >
                {getTechIcon(tech)}
                <span>{tech}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeProjectModal}
        contentLabel="Project Details"
        className="project-modal"
        overlayClassName="project-modal-overlay"
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none',
            background: '#1a1a1a',
            overflow: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '30px',
            maxWidth: '1200px',
            width: '95%',
            maxHeight: '90vh',
          }
        }}
      >
        {selectedProject && (
          <div className="project-modal-content">
            <h2>{selectedProject.title}</h2>
            <p className="project-date">{selectedProject.date}</p>
            <div className="project-modal-top">
              <div className="project-modal-left">
                <div className="project-description">
                  <h3>Project Overview</h3>
                  <p>{selectedProject.description}</p>
                </div>
                {selectedProject.roles && (
                  <div className="project-roles">
                    <h3>Roles</h3>
                    <ul>
                      {selectedProject.roles.map((role, roleIndex) => (
                        <li key={roleIndex}>{role}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {selectedProject.details && (
                  <div className="project-achievements">
                    <h3>Key Achievements</h3>
                    <ul>
                      {selectedProject.details.map((detail, detailIndex) => (
                        <li key={detailIndex}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="project-modal-right">
                <div className="project-technologies">
                  <h3>Technologies Used</h3>
                  <div className="tech-icons-full">
                    {selectedProject.technologies.map((tech, techIndex) => (
                      <div key={techIndex} className="tech-icon-full">
                        {getTechIcon(tech)}
                        <span>{tech}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {selectedProject.images && (
              <div className="project-images">
                <h3>Project Images</h3>
                <div className="project-images-container">
                  {selectedProject.images.map((image, imageIndex) => (
                    <img
                      key={imageIndex}
                      src={image}
                      alt={`${selectedProject.title} - Image ${imageIndex + 1}`}
                      onClick={() => openImageModal(image)}
                    />
                  ))}
                </div>
              </div>
            )}
            <button className="close-modal" onClick={closeProjectModal}>×</button>
          </div>
        )}
      </Modal>
      {imageModalIsOpen && selectedImage && createPortal(
        <div className="image-modal" onClick={closeImageModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Enlarged" />
            <button className="close-image-modal" onClick={closeImageModal}>×</button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default Tech;